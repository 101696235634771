<template>
	<div class="page">
		
		<van-swipe class="activity-swipe" :autoplay="3000">
			<van-swipe-item v-for="(item,index) in banner" :key="index">
				<van-image :src="item.location" width="100%" height="6rem" fit="fit"></van-image>
			</van-swipe-item>
		</van-swipe>
		
		<div style="padding-bottom: 10px;"></div>
		
		<van-grid :column-num="5" class="menu" :border="false">
		  <van-grid-item :icon="item.image" :text="item.category_name" @click="toList(item.id)" v-for="(item, index) in category" :key="index" />
		</van-grid>
		
		<div class="activity-page">
			<div style="margin-top: 10px;background-color: white;font-size: 16px;padding: 10px;">热门活动</div>
			<no-data v-if="activity_list.length==0"></no-data>
			<van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh" v-else>
				<van-list finished-text="加载完毕" :finished="finished" v-model="isloading" @load="loadData">
					
					<div v-for="(item,index) in activity_list" :key="index" @click="toActivity(item.id)" >
						<div class="my-list-item">
							<van-image class="image" :src="item.banner_images ? item.banner_images[0] : ''" fit="cover"></van-image>
							<div class="content">
								<div class="title">{{item.activity_name?item.activity_name:'空标题'}}</div>
								<div class="tip" v-if="item.is_show_person_switch">
									<span class="text-theme2" v-if="item.activitycategory">行业：{{item.activitycategory.category_name}}   </span>
									已报名:
									<span class="text-theme2">{{item.tickets_Count?item.tickets_Count:0}}</span>
								</div>
							</div>
							<div class="state">
								<span class="status bg-disabled" v-if="item.activity_status==0">未发布</span>
								<span class="status bg-theme4-color" v-if="item.activity_status==1">进行中</span>
							</div>
						</div>
						<div class="my-time">
							活动时间：{{item.start_time+'至'+item.end_time}}
						</div>
					</div>
		
				</van-list>
			</van-pull-refresh>
			
		</div>
		
		<div style="padding-bottom: 100px;"></div>
		
		<van-grid clickable :column-num="3" center icon-size="25px"  class="nav-bottom text-base">
		  <van-grid-item icon="wap-home-o" text="首页" class="text-theme2" :to="{path:'/home'}" />
		  <van-grid-item icon="flag-o" text="同城" :to="{path:'/home/nearby'}" />
		  <van-grid-item icon="manager-o" text="我的" :to="{path:'/user'}" />
		</van-grid>
	</div>
</template>

<script>
	import { Swipe, 
	SwipeItem, 
	Image, 
	Grid, 
	GridItem, 
	List,
	Row,
	Col,
	PullRefresh,
	} from 'vant'
	import { index_getlistbycity, 
	index_getlistbycategory, 
	index_getbanner,  
	index_getcategory,
	activity_list
	} from '../../request/api.js'
	export default {
		components: {
			[Swipe.name]: Swipe,
			[SwipeItem.name]: SwipeItem,
			[Image.name]: Image,
			[Grid.name]: Grid,
			[GridItem.name]: GridItem,
			[List.name]: List,
			[PullRefresh.name]: PullRefresh
		},
		data () {
			return {
				banner: [],
				category: [],
				
				current_page: 0,
				has_more: true,
				activity_list: [],
				isLoading: false, //向上加载
				isloading: false, //向下加载
				finished: false,
				ca_page: 1
			}
		},
		mounted(){
			this.getBanner()
			this.getCategory()
			this.loadData()
		},
		methods: {
			// 获取轮播图
			getBanner(){
				index_getbanner({
					
				}).then(res => {
					if (res.code == 1) {
						this.banner = res.data
					} else {
						this.$dialog({message: res.msg})
					}
				}).catch(err => {
					this.$dialog({message: '获取轮播图失败'})
 					console.log(err);
				})
			},
			// 获取行业类型
			getCategory(){
				index_getcategory({
					
				}).then(res => {
					if (res.code == 1) {
						this.category = res.data
					} else {
						this.$dialog({message: res.msg})
					}
				}).catch(err => {
					this.$dialog({message: '获取行业类别失败'})
				})
			},
			toList(id){
				this.$router.push({
					path: '/home/activity_list',
					query: {
						id: id
					}
				})
			},
			loadData() {
			
				//1、判断是否有更多 
				if (this.finished) {
					return;
				}
				//2、显示加载状态
				this.isloading = true;
			
				activity_list({
					is_hot: 1
				})
					.then(res => {
						if (res.code == 1) {
							//4、判断上下加载
							if (this.isLoading) {
								this.isLoading = false;
								this.hideLoading();
								this.activity_list = res.data.data;
							} else {
								this.activity_list = this.activity_list.concat(res.data.data);
							}
							//5、关闭加载状态 判断是否加载完成 
							this.isloading = false;
							this.has_more = res.data.has_more;
							if (res.data.has_more) {
								this.ca_page++
							} else {
								this.finished = true;
							}
						} else {
							this.$dialog({
								message: res.msg
							});
						}
					})
					.catch(err => {
						this.$dialog({
							message: "获取活动数据有误，请刷新页面"
						});
					});
			},
			
			onRefresh() {
				//初始化状态
				this.isLoading = true;
				this.has_more = true;
				this.finished = false;
				this.current_page = 0;
				this.ca_page = 1;
				this.showLoading('刷新中', true);
				this.loadData();
			},
			
			toActivity(id){
				this.$router.push({
					path: '/activity',
					query: {
						id: id
					}
				})
			},
			
		}
	}
</script>

<style scoped="true" lang="less">
	.activity-swipe {
		font-size: 0;
	}
	
	.activity-page {
		.van-pull-refresh {
			margin-top: 5px;
		}
	
	}
	
	.my-list-item{
		display: flex;
		align-items: center;
		padding: 10px 10px;
		padding-bottom: 0;
		background-color: white;
		.image{
			width: 130px;height: 94px;margin-right: 3px;
		}
		.content{
			width: 165px;
			height: 94px;
			padding-left: 5px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.title{
				font-size: 16px;
				width: 100%;
				height: 70px;
				opacity: hidden;
				text-overflow: ellipsis;
			}
			.tip{
				white-space: nowrap;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: 14px;
			}
		}
		.state{
			.status{
				color: white;
				font-size: 14px;
				padding: 5px;
				border-radius: 5px;
			}
		}
	}
	.my-time{
		background-color: white;
		font-size: 12px;
		color: #888;
		padding: 10px;
		border-bottom: 1px solid #f2f2f2;
	}
	.activity-page .van-pull-refresh{
		margin-top: 0;
	}
	.menu {
		/deep/ .van-grid-item__content{
		}
		/deep/ .van-icon__image{
			border-radius: 50%;
			overflow: hidden;
		}
	}
</style>
